import React, { useState } from "react"
import { format, add, setDefaultOptions, addDays, startOfDay } from "date-fns"

import { it } from "date-fns/locale"

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { closedDateRanges } from "../../helpers/closedDates"

import {
  BiBed,
  BiUser,
  BiPlusCircle,
  BiMinusCircle,
  BiXCircle,
} from "react-icons/bi"
import { motion, AnimatePresence } from "framer-motion"
import { navigate } from "gatsby"

import { useSearchParameters } from "../../stores/searchParameters"

const BookingSearch = ({}) => {
  const language = process.env.GATSBY_SITE_LANGUAGE

  const timeZone = "Europe/Rome"

  if (language === "it") {
    setDefaultOptions({ locale: it })
    registerLocale("it", it)
  }

  const toUTCDate = date => {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
  }

  const utcClosedDateRanges = closedDateRanges.map(range => ({
    start: toUTCDate(range.start),
    end: toUTCDate(range.end),
  }))

  // const tomorrow = startOfDay(addDays(new Date(), 1))
  // const minDate = toUTCDate(tomorrow)

  const minDate = toUTCDate(new Date("2025-06-01"))

  const {
    checkInDate,
    setCheckInDate,
    checkOutDate,
    setCheckOutDate,
    totalGuests,
    setTotalGuests,
    totalRooms,
    setTotalRooms,
  } = useSearchParameters()

  const [guestAndRooms, setGuestAndRooms] = useState(false)

  const [showErrors, setShowErrors] = useState(false)
  const [dateCheckInDateSelectionError, setCheckInDateSelectionError] =
    useState(null)
  const [dateCheckOutDateSelectionError, setCheckOutDateSelectionError] =
    useState(null)
  const [roomSelectionError, setRoomSelectionError] = useState(null)

  const localeStrings = { one: "night", other: "nights" }

  const placeHolderDate = date => {
    return format(date, "do MMM yyyy")
  }

  const guests = dir => {
    if (dir === "subtract") {
      if (totalGuests !== 1) {
        setTotalGuests(totalGuests - 1)
        // performSearch()
      }
    }
    if (dir === "add") {
      if (totalGuests !== 6) {
        setTotalGuests(totalGuests + 1)
        // performSearch()
      }
    }
  }

  const rooms = dir => {
    if (dir === "subtract") {
      if (totalRooms !== 1) {
        setTotalRooms(totalRooms - 1)
        // performSearch()
      }
    }
    if (dir === "add") {
      if (totalRooms !== 6) {
        setTotalRooms(totalRooms + 1)
        // performSearch()
      }
    }
  }

  const performSearch = () => {
    let canSubmit = true
    setGuestAndRooms(false)

    if (!checkInDate) {
      setCheckInDateSelectionError(
        language === "en" ? "Please enter a date" : "Inserisci una data"
      )
      canSubmit = false
    }
    if (!checkInDate) {
      setCheckOutDateSelectionError(
        language === "en" ? "Please enter a date" : "Inserisci una data"
      )
      canSubmit = false
    }

    let selectedRoomUrl = ""

    if (totalGuests === 1) {
      if (totalRooms === 1) {
        // setRoomSelection([{ 'room': 'double-1-guest' }])
        selectedRoomUrl =
          language === "en" ? "1-guest-1-room" : "1-ospite-1-camera"
        setRoomSelectionError(null)
      } else {
        // setRoomSelection(null)
        setRoomSelectionError(
          language === "en"
            ? "You only need one room for one guest"
            : "Hai solo bisogno di una stanza per un ospite"
        )
        canSubmit = false
      }
    } else if (totalGuests === 2) {
      if (totalRooms === 1) {
        // setRoomSelection([{ 'room': 'double-2-guests' }])
        selectedRoomUrl =
          language === "en" ? "2-guests-1-room" : "2-ospiti-1-camera"
        setRoomSelectionError(null)
      } else if (totalRooms === 2) {
        // setRoomSelection([{ 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }])
        selectedRoomUrl =
          language === "en" ? "2-guests-2-rooms" : "2-ospiti-2-camere"
        setRoomSelectionError(null)
      } else {
        // setRoomSelection([])
        setRoomSelectionError(
          language === "en"
            ? "You do not need more than 2 rooms for 2 guests"
            : "Non hai bisogno di più di 2 camere per 2 persone"
        )
        canSubmit = false
      }
    } else if (totalGuests === 3) {
      if (totalRooms === 1) {
        // setRoomSelection([])
        setRoomSelectionError(
          language === "en"
            ? "You need more rooms for this number of guests"
            : "Hai bisogno di più camere per questo numero di ospiti"
        )
        canSubmit = false
      } else if (totalRooms === 2) {
        // setRoomSelection([{ 'room': 'mountain-cottage' }])
        selectedRoomUrl =
          language === "en" ? "3-guests-2-rooms" : "3-ospiti-2-camere"
        setRoomSelectionError(null)
      } else if (totalRooms === 3) {
        // setRoomSelection([{ 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }])
        selectedRoomUrl =
          language === "en" ? "3-guests-3-rooms" : "3-ospiti-3-camere"
        setRoomSelectionError(null)
      } else {
        // setRoomSelection([])
        setRoomSelectionError(
          language === "en"
            ? "You do not need more than 3 rooms for 3 guests"
            : "Non hai bisogno di più di 3 camere per 3 persone"
        )
        canSubmit = false
      }
    } else if (totalGuests === 4) {
      if (totalRooms === 2) {
        // setRoomSelection([{ 'room': 'mountain-cottage'}])
        selectedRoomUrl =
          language === "en" ? "4-guests-2-rooms" : "4-ospiti-2-camere"
        setRoomSelectionError(null)
      } else if (totalRooms === 3) {
        // setRoomSelection([{ 'room': 'double-2-guests' }, { 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }])
        selectedRoomUrl =
          language === "en" ? "4-guests-3-rooms" : "4-ospiti-3-camere"
        setRoomSelectionError(null)
      } else if (totalRooms === 4) {
        // setRoomSelection([{ 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }])
        selectedRoomUrl =
          language === "en" ? "4-guests-4-rooms" : "4-ospiti-4-camere"
        setRoomSelectionError(null)
      } else {
        // setRoomSelection([])
        setRoomSelectionError(
          language === "en"
            ? "You do not need more than 4 rooms for 4 guests"
            : "Non hai bisogno di più di 4 camere per 4 persone"
        )
        canSubmit = false
      }
    } else if (totalGuests === 5) {
      if (totalRooms < 3) {
        // setRoomSelection([])
        setRoomSelectionError(
          language === "en"
            ? "You need more rooms for this number of guests"
            : "Hai bisogno di più camere per questo numero di ospiti"
        )
        canSubmit = false
      } else if (totalRooms === 3) {
        // setRoomSelection([{ 'room': 'garden-cottage' }])
        selectedRoomUrl =
          language === "en" ? "5-guests-3-rooms" : "5-ospiti-3-camere"
        setRoomSelectionError(null)
      } else if (totalRooms === 4) {
        // setRoomSelection([{ 'room': 'double-2-guests' }, { 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }])
        selectedRoomUrl =
          language === "en" ? "5-guests-4-rooms" : "5-ospiti-4-camere"
        setRoomSelectionError(null)
      } else if (totalRooms === 5) {
        // setRoomSelection([{ 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }])
        selectedRoomUrl =
          language === "en" ? "5-guests-5-rooms" : "5-ospiti-5-camere"
        setRoomSelectionError(null)
      } else {
        // setRoomSelection([])
        setRoomSelectionError(
          language === "en"
            ? "You do not need more than 5 rooms for 5 guests"
            : "Non hai bisogno di più di 5 camere per 5 persone"
        )
        canSubmit = false
      }
    } else if (totalGuests === 6) {
      if (totalRooms < 3) {
        // setRoomSelection([])
        setRoomSelectionError(
          language === "en"
            ? "You need more rooms for this number of guests"
            : "Hai bisogno di più camere per questo numero di ospiti"
        )
        canSubmit = false
      } else if (totalRooms === 3) {
        // setRoomSelection([{ 'room': 'garden-cottage' }])
        selectedRoomUrl =
          language === "en" ? "6-guests-3-rooms" : "6-ospiti-3-camere"
        setRoomSelectionError(null)
      } else if (totalRooms === 4) {
        // setRoomSelection([{ 'room': 'double-2-guests' }, { 'room': 'double-2-guests' }, { 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }])
        selectedRoomUrl =
          language === "en" ? "6-guests-4-rooms" : "6-ospiti-4-camere"
        setRoomSelectionError(null)
      } else if (totalRooms === 5) {
        // setRoomSelection([{ 'room': 'double-2-guest' }, { 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }, {'room': 'double-1-guest' }])
        selectedRoomUrl =
          language === "en" ? "6-guests-5-rooms" : "6-ospiti-5-camere"
        setRoomSelectionError(null)
      }
      if (totalRooms === 6) {
        // setRoomSelection([{ 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }, { 'room': 'double-1-guest' }])
        selectedRoomUrl =
          language === "en" ? "6-guests-6-rooms" : "6-ospiti-6-camere"
        setRoomSelectionError(null)
      }
    }

    if (canSubmit) {
      navigate(
        `/${
          language === "en" ? "summer-experience" : "summer-experience"
        }/${selectedRoomUrl}/`
      )
    } else {
      setShowErrors(true)
    }
  }

  const CustomInputCheckIn = ({ onClick }) => {
    const placeholder = checkInDate
      ? placeHolderDate(checkInDate)
      : language === "en"
      ? "Select Date"
      : "Scegli la data"

    return (
      <button
        className="px-4 pt-2 pb-1 w-full h-full text-left"
        onClick={onClick}
      >
        {placeholder}
      </button>
    )
  }

  const CustomInputCheckOut = ({ onClick }) => {
    const placeholder = checkOutDate
      ? placeHolderDate(checkOutDate)
      : language === "en"
      ? "Select Date"
      : "Scegli la data"

    return (
      <button
        className="px-4 pt-2 pb-1 w-full h-full text-left"
        onClick={onClick}
      >
        {placeholder}
      </button>
    )
  }

  return (
    <div className="flex flex-wrap gap-4 p-4 mt-4 rounded md:bg-[#f3f2f1] md:flex-row md:justify-between">
      <div className="w-[45%] md:w-[48.5%] lg:w-[20%] pt-4 md:pt-0">
        <p className="text-sm font-bold text-[#f3f2f1] md:text-black">
          {language === "en" ? "Check In" : "Arrivo"}:
        </p>
        <div
          className="bg-white rounded border border-gray-400"
          onClick={() => setGuestAndRooms(false)}
        >
          <DatePicker
            className="p-0 w-full datepicker"
            minDate={minDate}
            onSelect={date => {
              setCheckInDate(date)
              setCheckOutDate(null)
            }}
            daysLocale={localeStrings}
            onFocus={e => (e.target.readOnly = true)}
            customInput={<CustomInputCheckIn />}
            locale={language}
            excludeDateIntervals={utcClosedDateRanges}
            showDisabledMonthNavigation
            selectsRange
          />
        </div>
        {dateCheckInDateSelectionError && (
          <p className="pl-2 mt-2 text-xs text-red-600">
            {dateCheckInDateSelectionError}
          </p>
        )}
      </div>
      <div className="w-[45%] md:w-[48.5%] lg:w-[20%] pt-4 md:pt-0">
        <p className="text-sm font-bold text-[#f3f2f1] md:text-black">
          {language === "en" ? "Check Out" : "Partenza"}:
        </p>
        <div
          className="bg-white rounded border border-gray-400"
          onClick={() => setGuestAndRooms(false)}
        >
          <DatePicker
            className="w-full datepicker"
            minDate={checkInDate ? add(checkInDate, { days: 5 }) : minDate}
            onSelect={date => {
              setCheckOutDate(date)
            }}
            daysLocale={localeStrings}
            onFocus={e => (e.target.readOnly = true)}
            customInput={<CustomInputCheckOut />}
            locale={language}
            excludeDateIntervals={utcClosedDateRanges}
            showDisabledMonthNavigation
            selectsRange
          />
        </div>
        {dateCheckOutDateSelectionError && (
          <p className="pl-2 mt-2 text-xs text-red-600">
            {dateCheckInDateSelectionError}
          </p>
        )}
      </div>
      <div className="w-[310px] md:w-[48.5%] lg:w-[30%] pt-4 md:pt-0">
        <p className="hidden text-sm font-bold md:block">
          {language === "en" ? "Guests and Rooms" : "Ospiti e Camere"}:
        </p>
        <div className="relative">
          <div
            className={`bg-white relative px-4 py-2 border border-gray-400 rounded ${
              guestAndRooms ? `rounded-bl-none` : `rounded`
            } `}
          >
            <button
              className="flex w-full"
              onClick={e => setGuestAndRooms(!guestAndRooms)}
            >
              <div className="flex items-center w-[120px]">
                <BiUser className="mr-2" size="1.2rem" />{" "}
                <p className="text-sm">
                  {totalGuests}{" "}
                  {totalGuests !== 1
                    ? language === "en"
                      ? "Guests"
                      : "Ospiti"
                    : language === "en"
                    ? "Guest"
                    : "Ospite"}
                </p>
              </div>
              <div className="flex items-center w-[120px]">
                <BiBed className="mr-2" size="1.2rem" />{" "}
                <p className="text-sm">
                  {totalRooms}{" "}
                  {totalRooms !== 1
                    ? language === "en"
                      ? "Rooms"
                      : "Camere"
                    : language === "en"
                    ? "Room"
                    : "Camera"}
                </p>
              </div>
            </button>
          </div>
          <AnimatePresence>
            {guestAndRooms && (
              <motion.div
                initial={{ opacity: 0, y: -25 }}
                animate={{ opacity: 1, y: -1 }}
                exit={{ opacity: 0, y: -25 }}
                className="absolute left-0 max-w-[350px] z-50 flex items-center px-4 py-5 bg-white border border-t-0 border-gray-400 rounded rounded-t-none"
              >
                <div className="flex items-center w-[120px]">
                  <button className="" onClick={e => guests("add")}>
                    <BiPlusCircle size="1.5rem" />
                  </button>
                  <BiUser className="mx-2" size="1.2rem" />
                  <button className="" onClick={e => guests("subtract")}>
                    <BiMinusCircle size="1.5rem" />
                  </button>
                </div>
                <div className="flex items-center  w-[120px]">
                  <button className="" onClick={e => rooms("add")}>
                    <BiPlusCircle size="1.5rem" />
                  </button>
                  <BiBed className="mx-2" size="1.2rem" />
                  <button className="" onClick={e => rooms("subtract")}>
                    <BiMinusCircle size="1.5rem" />
                  </button>
                </div>
                <div className="flex justify-center items-center ml-auto">
                  <button className="" onClick={e => setGuestAndRooms(false)}>
                    <BiXCircle size="1.5rem" />
                  </button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {roomSelectionError && (
          <p className="pl-2 mt-2 text-xs text-red-600">{roomSelectionError}</p>
        )}
      </div>
      <div className="flex items-bottom justify-end md:w-[48.5%] lg:w-[23%] mt-2 md:mt-5 max-h-[58px]">
        <button
          type="button"
          className="relative inline-flex items-center justify-center text-sm font-bold leading-none text-white border border-solid button rounded-4 bg-aqua-500 border-aqua-500 hover:border-opacity-80 hover:bg-opacity-80 focus:outline-aqua-500 min-w-[200px] md:w-full"
          onClick={performSearch}
        >
          <span>{language === "en" ? "Search" : "Cerca"}</span>
        </button>
      </div>
    </div>
  )
}

export default BookingSearch
